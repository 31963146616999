<template>
  <div></div>
</template>

<script>
import Cookies from "js-cookie";
import { getWxOauth } from "@/api/user.js";
import mixins from "@/utils/mixins.js";
export default {
  mixins: [mixins],
  async created() {
  const toast= this.$toast({
      type:'loading',
      message: this.$t("加载中"),
      duration: 0
    })
    if (this.isWeiXin()) {
      // window.location.href = res1.data;
      Cookies.set("wxCode", this.$route.query.code);
      Cookies.set("wxState", this.$route.query.state);
    }
    if (window.localStorage.getItem("wxPayPath")) {
      this.$router.push(window.localStorage.getItem("wxPayPath")+"?flag=true");
    }
    toast.close()
  }
};
</script>

<style></style>
